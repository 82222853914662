import React, { useCallback, useState } from "react";
import { Box, Grid, makeStyles } from "@material-ui/core";
import ReactLoading from "react-loading";
import Table from "../components/table/Table";
import Layout from "../layout";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {  getReadNotification } from "../redux/user/action";
import debounce from "lodash.debounce";


const useStyles = makeStyles({
    component: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
    },
    card: {
        display: "flex",
        justifyContent: "space-between",
    },
    progress: {
        display: "flex",
        justifyContent: "space-between",
        padding: "35px 0px",
    },
});

const tableHead = [
    { id: 1, title: "S.No." },
    // { id: 2, title: "First Name" },
    // { id: 3, title: "Last Name" },
    { id: 4, title: "Message" },
    { id: 5, title: "Date" },
    { id: 6, title: "Time" },
    // { id: 7, title: "Status" }
];

const Notifications = () => {
    const [data, setSearchData] = useState(null);
    const [dbvalue, setDbvalue] = useState(null);

    const [filter, setFilter] = useState(null)
    const [filterCountNumber, setFilterCountNumber] = useState(1)
    const classes = useStyles();
    const dispatch = useDispatch();

    const getReadNotifications = useSelector(
        ({ getReadNotificationReducer }) => getReadNotificationReducer
    );

    const listNotification = getReadNotifications?.items;
    const debounsedSaved = useCallback(
        debounce((data) => setDbvalue(data), 1000),
        []
    );
    debounsedSaved(data);

    const FilterlistNotification = listNotification?.items?.count == undefined ? 0 : listNotification?.items?.count;

    console.log("FilterlistNotification",listNotification);

    useEffect(() => {
        dispatch(getReadNotification(filterCountNumber, dbvalue))
    }, [filterCountNumber, dbvalue])

    const setfilterVal = (val) => {
        setFilter(val)
    }
    const setFilterCount = (count) => {
        setFilterCountNumber(count)
    }

    const listReadNotifications = getReadNotifications?.items;
    return (
        <>
            <Layout>
                <Box className={classes.component}>
                    <Box>
                        {getReadNotifications?.loading && (
                            <Grid
                                container
                                justifyContent="center"
                                style={{
                                    height: "100vh",
                                    width: "100%",
                                    position: "absolute",
                                }}
                                alignItems="center"
                            >
                                <ReactLoading type="spinningBubbles" color="blue" />
                            </Grid>

                        )}
                        <Table
                            name="Notifications"
                            tableTitle="Notifications"
                            tableHead={tableHead}
                            tableBody={listReadNotifications}
                            paginate={true}
                            type="notifications"
                            TableFilterCoun={FilterlistNotification}
                            items="false"
                            // setfilterVal={setfilterVal}
                            setFilterCount={setFilterCount}
                            filtercount={listReadNotifications?.data || []}
                            filtertransactions={getReadNotifications}
                            search={false}
                            // dbvalue={dbvalue || null}
                            // setSearchData={setSearchData}
                        />
                    </Box>
                </Box>
            </Layout>
        </>
    );
};

export default Notifications;
