import { Box, CircularProgress, Grid, makeStyles } from "@material-ui/core";
import Layout from "../layout";
import Table from "../components/table/Table";
import { useDispatch, useSelector } from "react-redux";
import ReactLoading from "react-loading";
import { fetchLocalkings, fetchLocalkingsFilter } from "../redux/user/action";
import { useCallback, useEffect, useState } from "react";
import debounce from "lodash.debounce";
import { LocalKingFilterReducer } from "../redux/user/reducer";

const useStyles = makeStyles({
  component: {
    display: "flex",
    flexDirection: "column",
    position: "relative ",
  },
  card: {
    display: "flex",
    justifyContent: "space-between",
  },
  progress: {
    display: "flex",
    justifyContent: "space-between",
    padding: "35px 0px",
  },
  searchIcon: {
    position: "relative",
    float: "right",
    width: "75px",
    height: "75px",
    top: "-62px",
    right: "-45px",
  },
  searchContainer: {
    width: "290px",
    display: "block",
    margin: "0 auto",
  },
  searchbar: {
    margin: "0 auto",
    width: "100%",
    height: "45px",
    padding: "0 20px",
    fontSize: "1rem",
    border: "1px solid #D0CFCE",
    outline: "none",
  },
});

const tableHead = [
  { id: 1, title: "S.No" },
  { id: 2, title: "First Name" },
  { id: 3, title: "Last Name" },
  { id: 4, title: "Email" },
  { id: 5, title: "Mobile" },
  { id: 6, title: "Rating" },
  { id: 7, title: "Customer" },
  // { id: 8, title: "Action" },
];

export default function ListLocalking() {
  const classes = useStyles();

  const [data, setSearchData] = useState(null);
  const [dbvalue, setDbvalue] = useState();

  const [filterCountNumber, setFilterCountNumber] = useState(1);

  const localkings = useSelector(({ localkingReducer }) => localkingReducer);
  const listLocalkings = localkings?.items;

  const LocalkingFilter = useSelector(
    ({ LocalKingFilterReducer }) => LocalKingFilterReducer
  );

  const listFilterLocalKing = LocalkingFilter?.items;

  const FilterLocalkingcount =
    LocalkingFilter?.items?.count == undefined
      ? 0
      : LocalkingFilter?.items?.count;

  const debounsedSaved = useCallback(
    debounce((data) => setDbvalue(data), 1000),
    []
  );
  debounsedSaved(data);

  const dispatch = useDispatch();
  const count =
    FilterLocalkingcount == 0
      ? filterCountNumber
      : Math.ceil(FilterLocalkingcount / 5);

  useEffect(() => {
    dispatch(fetchLocalkingsFilter(dbvalue, filterCountNumber));
    console.log("object");
  }, [dbvalue, filterCountNumber, localkings, count]);

  useEffect(() => {
    dispatch(fetchLocalkings(filterCountNumber));
  }, [filterCountNumber]);

  useEffect(() => {
    if (dbvalue != "" || dbvalue != null) {
      setSearchData(dbvalue);
      dispatch(fetchLocalkingsFilter(dbvalue, setFilterCountNumber(1)));
      return
    }
    if (dbvalue == "" || dbvalue == null) {
      setSearchData(dbvalue);
      dispatch(fetchLocalkingsFilter(dbvalue, setFilterCountNumber(1)));
      return
    }
  }, [dbvalue]);

  const setFilterCount = (count) => {
    setFilterCountNumber(count);
  };

  return (
    <>
      <Layout>
        <Box className={classes.component}>
          <Box>
            {localkings.loading == false ||
              (LocalkingFilter.loading && (
                <Grid
                  container
                  justifyContent="center"
                  style={{
                    height: "110vh",
                    width: "100%",
                    position: "absolute",
                  }}
                  alignItems="center"
                >
                  {/* <CircularProgress /> */}
                </Grid>
              ))}
            <Table
              name="Users"
              subtitle="List of LocalKing"
              tableTitle="LocalKing"
              tableHead={tableHead}
              type="localkingList"
              tableBody={listLocalkings}
              filtercount={listFilterLocalKing?.data || []}
              isLoadingData={LocalkingFilter?.loading || false}
              TableFilterCoun={FilterLocalkingcount}
              paginate={true}
              dbvalue={dbvalue || null}
              NavigateLocation={"Add-Localking"}
              setFilterCount={setFilterCount}
              setSearchData={setSearchData}
              filterCountNumber={filterCountNumber}
            />
          </Box>
        </Box>
      </Layout>
    </>
  );
}
