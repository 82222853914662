import { Box, Grid, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import Card from "./card/Card";
import { useDispatch, useSelector } from "react-redux";
// import ApexCharts from "apexcharts";
import Table from "../table/Table.js";
import Chart from "react-apexcharts";
import {
  cancelledOrder,
  fetchLocalkings,
  recentFiveOrders,
} from "../../redux/user/action";
import { border } from "@mui/system";

const useStyles = makeStyles({
  title: {
    fontWeight: 700,
    fontSize: "38px",
    lineHeight: "35px",
    color: "rgb(0,58,210)",
    marginBottom: "2em",
  },
  progress: {
    padding: "35px 0px",
    minHeight: "200px",
  },
  donutBox: {
    background: "rgb(255, 255, 255)",
    borderRadius: "10px",
    boxShadow: "0px 6px 18px rgba(0, 0, 0, 0.06)",
    margin: "10px 0px",
    height: "100%",
    "& .apexcharts-legend-series": {
      margin: "0px 0px 11px 0px !important",
    },
  },
  donutTagName: {
    padding: "10px 20px",
    fontSize: "15px",
    color: "#192A3E",
    borderBottom: "2px solid rgb(224,224,224)",
  },
  donutCricle: {
    padding: "10px 0px",
  },
  widget_card: {
    margin: "10px 0px",
  },
  orders_circle_progress: {
    background: "rgb(255, 255, 255)",
    borderRadius: "10px",
    boxShadow: "0px 6px 18px rgba(0, 0, 0, 0.06)",
    margin: "10px 0px",
    height: "100%",
    "& .apexcharts-legend.apx-legend-position-right": {
      top: "10px !important",
      position: "relative !important",
      float: "right",
    },
    "& .apexcharts-legend-series": {
      margin: "0px 0px 11px 0px !important",
    },
  },
});

export default function Widgetss() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchLocalkings());
    dispatch(recentFiveOrders());
  }, []);
  // style copmonent .......
  const classes = useStyles();

  //   All APIs Response.........
  const orders = useSelector(
    ({ recentFiveOrderReducer }) => recentFiveOrderReducer
  );
  const totalNumberOfCustomer = useSelector(
    ({ customerReducer }) => customerReducer?.items?.count
  );
  const totalOrder = useSelector(
    ({ cancelOrderReducer }) => cancelOrderReducer?.items
  );

  const local_King_Reducre = useSelector(
    ({ localkingReducer }) => localkingReducer?.items?.count
  );

  const totalNumberOfLK =
    local_King_Reducre == undefined ? 0 : local_King_Reducre;

  const totalNumberOfCustomers = totalNumberOfCustomer
    ? totalNumberOfCustomer
    : 0;

  // Filtring..........................
  const dataReturnBring = totalOrder?.filter(
    (item) => item?.service === "Return" && item?.subService === "Bring"
  );
  const dataReturnFetch = totalOrder?.filter(
    (item) => item?.service === "Return" && item?.subService === "Fetch"
  );
  const dataDeliveryBring = totalOrder?.filter(
    (item) => item?.service === "Delivery" && item?.subService === "Bring"
  );

  const dataDeliveryFetch = totalOrder?.filter(
    (item) => item?.service === "Delivery" && item.subService === "Fetch"
  );
  const inProgressData = totalOrder?.filter(
    (item) =>
      item?.orderStatus === "Delivered" ||
      item?.orderStatus === "Processing" ||
      item?.orderStatus === "Handover" ||
      item?.orderStatus === "Fetched" ||
      item?.orderStatus === "Receipt"
  );
  const completedData = totalOrder?.filter(
    (item) =>
      item?.orderStatus === "Confirmed" ||
      item?.orderStatus === "Recieved" ||
      item.orderStatus === "Review"
  );
  const cancelledData = totalOrder?.filter(
    (item) =>
      item?.orderStatus === "Cancelled" || item?.orderStatus === "Rejected"
  );

  const myData = totalOrder?.filter(
    (item) =>
      item?.orderStatus !== "Delivered" &&
      item?.orderStatus !== "Processing" &&
      item?.orderStatus !== "Handover" &&
      item?.orderStatus !== "Fetched" &&
      item?.orderStatus !== "Receipt" &&
      item?.orderStatus !== "Recieved" &&
      item.orderStatus !== "Review" &&
      item?.orderStatus !== "Cancelled" &&
      item?.orderStatus !== "Rejected"
  );

  // const totalDelivered = totalOrder?.filter(
  //   (item) => item?.orderStatus === "Delivered"
  // );
  // const totalProcessing = totalOrder?.filter(
  //   (item) => item?.orderStatus === "Processing"
  // );
  // const totalHandover = totalOrder?.filter(
  //   (item) => item?.orderStatus === "Handover"
  // );
  // const totalFetched = totalOrder?.filter(
  //   (item) => item?.orderStatus === "Delivered"
  // );
  // const totalReceipt = totalOrder?.filter(
  //   (item) => item?.orderStatus === "Receipt"
  // );
  // const totalPayment = totalOrder?.filter(
  //   (item) => item?.orderStatus === "Payment"
  // );
  // const totalRecieved = totalOrder?.filter(
  //   (item) => item?.orderStatus === "Recieved"
  // );
  // const totalReview = totalOrder?.filter(
  //   (item) => item?.orderStatus === "Review"
  // );
  // const totalCancelled = totalOrder?.filter(
  //   (item) => item?.orderStatus === "Cancelled"
  // );
  // const totalRejected = totalOrder?.filter(
  //   (item) => item?.orderStatus === "Rejected"
  // );

  // console.log("totaldata:", totalOrder.length);
  // // console.log("totalDelivered:",totalDelivered.length);
  // // console.log("totalProcessing:",totalProcessing.length);
  // // console.log("totalHandover:",totalHandover.length);
  // // console.log("totalFetched:",totalFetched.length);
  // // console.log("totalReceipt:",totalReceipt.length);
  // // console.log("totalPayment:",totalPayment.length);
  // // console.log("totalRecieved:",totalRecieved.length);
  // // console.log("totalReview:",totalReview.length);
  // // console.log("totalCancelled:",totalCancelled.length);
  // // console.log("totalRejected:",totalRejected.length);
  // console.log("total myData:", myData);
  // console.log("total++++++++++++++++++++++");

  const cardData = [
    {
      color: "linear-gradient(89.69deg, #F39034 2.03%, #FF2727 99.73%)",
      title: "Total No. of Services",
      quantity: totalOrder?.length ? totalOrder?.length : 0,
    },
    {
      color: "linear-gradient(270deg, #003AD2 0%, #0097EC 100%)",
      title: "Total No. of Customers",
      quantity: totalNumberOfCustomers ? totalNumberOfCustomers : 0,
    },
    {
      color: "linear-gradient(270.23deg, #00A843 0.19%, #1FD071 99.8%)",
      title: "Cancelled Services",
      quantity: cancelledData?.length ? cancelledData?.length : 0,
    },
    {
      color: "linear-gradient(269.97deg, #5900C9 0.02%, #9852F0 99.98%)",
      title: "Total No. of Riders",
      quantity: totalNumberOfLK ? totalNumberOfLK : 0,
    },
  ];

  const tableHead = [
    { id: 1, title: "S.No" },
    { id: 2, title: "Customer" },
    { id: 3, title: "Localking" },
    { id: 4, title: "Service" },
    { id: 5, title: "Status" },
  ];

  const tableBody = orders?.items;

  let chartData = [
    {
      title: "Users",
      width: "270",
      type: "donut",
      series: [
        totalNumberOfLK ?? 0,
        totalNumberOfCustomers ?? 0,
        totalNumberOfLK ?? 0,
      ],
      options: {
        labels: ["Rider", "Customer", "Both"],
        fontSize: "20px",
        colors: ["#FFB946", "#2ED47A", "#F7685B"],
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: true,
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  showAlways: true,
                  color: "black",
                  fontSize: "13px",
                },
              },
            },
          },
        },
      },
    },
    {
      // title: "Services",
      title: "Orders",
      width: "270",
      type: "donut",
      series: [
        inProgressData?.length ?? 0,
        completedData?.length ?? 0,
        cancelledData?.length ?? 0,
        // cancelledData?.length, // chnage by
      ],
      options: {
        labels: ["InProgress", "Completed", "Cancelled"],
        colors: ["#FFB946", "#2ED47A", "#F7685B"],
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: true,
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  showAlways: true,
                  color: "black",
                  fontSize: "13px",
                },
              },
            },
          },
        },
      },
    },
    {
      title: "Services",
      // title: "Orders",
      width: "290",
      type: "donut",
      series: [
        dataReturnBring?.length ?? 0,
        dataReturnFetch?.length ?? 0,
        dataDeliveryBring?.length ?? 0,
        dataDeliveryFetch?.length ?? 0,
      ],
      options: {
        labels: [
          "Return(Bring)",
          "Return(Fetch)",
          "Delivery(Bring)",
          "Delivery(Fetch)",
        ],
        colors: ["#FFB946", "#2ED47A", "#F7685B", "#58AAF0"],
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: true,
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  showAlways: true,
                  color: "black",
                  fontSize: "13px",
                },
              },
            },
          },
        },
      },
    },
  ];

  return (
    <>
      <Box className={classes.title}>Dashboard</Box>
      <Grid container spacing={3}>
        {cardData?.map((item) => (
          <Grid item xs={12} md={6} lg={3} key={item.id}>
            <Card
              className={`${classes.card}`}
              color={item.color}
              title={item.title}
              quantity={item.quantity}
            />
          </Grid>
        ))}
      </Grid>

      <div style={{ margin: "25px 0px" }}>
        <Grid container spacing={3}>
          {chartData.map((value, index) => {
            return (
              <>
                <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
                  <div
                    className={
                      value.title == "Services"
                        ? classes.orders_circle_progress
                        : classes.donutBox
                    }
                  >
                    <h4 className={classes.donutTagName}>{value.title}</h4>
                    {/* {value.series} */}
                    <div className={classes.donutCricle}>
                      <Chart
                        width={value.width}
                        type="donut"
                        // height={200}
                        series={value.series}
                        options={value.options}
                      />
                    </div>
                  </div>
                </Grid>
              </>
            );
          })}
        </Grid>
      </div>

      <div className="mt-5">
        <Box>
          <Table
            tableTitle="Recent Services"
            tableHead={tableHead}
            type="dashboard"
            tableBody={tableBody}
            paginate={false}
          />
        </Box>
      </div>
    </>
  );
}
