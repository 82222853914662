import { WindowSharp } from "@mui/icons-material";
import axios from "axios";
import swal from "sweetalert";
import sweetAlert from "./../../sweetAlertMessages";
import userActionTypes from "./action.types";
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css'; // for React, Vue and Svelte
import { axiosErrorHandler, responseModel } from "../../utils/responseModel";
const url = process.env.REACT_APP_URL_DEV;

axios.defaults.baseURL = url;

export const fetchCustomer = (CountNumber,dbvalue) => async (dispatch, getState) => {
    const value = dbvalue ? dbvalue : "";
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };
    dispatch({ type: userActionTypes.CUSTOMER_LIST_REQUEST });
    try {
        let response = await axios.get(
            `/users?currentRole=Customer&page=${CountNumber}&limit=10`,
            {
                headers,
            }
        );

        dispatch({
            type: userActionTypes.CUSTOMER_LIST_SUCCESS,
            payload: response.data,
        });

    } catch (err) {
        dispatch({ type: userActionTypes.CUSTOMER_LIST_FAILURE, err });
    }
};

export const fetchCustomerFilter = (dbvalue,pageCount) => async (dispatch, getState) => {
    // const value = dbvalue ? dbvalue : "";
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };

    dispatch({ type: userActionTypes.CUSTOMER_FILTER_LIST_REQUEST });
    try {
        const response = await axios.get(
            `users?currentRole=Customer&page=${pageCount}&limit=10&firstName=${dbvalue}`,
            {
                headers,
            }
        );
        dispatch({
            type: userActionTypes.CUSTOMER_FILTER_LIST_SUCCESS,
            payload: response.data,
        });
    } catch (err) {
        dispatch({ type: userActionTypes.CUSTOMER_FILTER_LIST_FAILURE, err });
    }
};


export const editUser = (id, data, currentPage) => async (dispatch, getState) => {
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };
    dispatch({ type: userActionTypes.EDIT_USER_REQUEST });
    try {
        let response = await axios.patch(`/users/${id}`, data, {
            headers,
        });

        const notyf = new Notyf({
            duration: 9000,
            position: {
                x: 'center',
                y: 'top',
            },

            types: [
                {
                    type: 'successs',
                    ripple: true,
                    icon: true,
                }
            ]
        });

        notyf.open({
            type: 'success',
            message: 'User Details Edited Successfuly'
        });

        dispatch({
            type: userActionTypes.EDIT_USER_SUCCESS,
            payload: response.data,
        });
        dispatch(fetchCustomer(currentPage));
        return responseModel(response.status, response.data);
    } catch (err) {
        const response = axiosErrorHandler(err);
        dispatch({ type: userActionTypes.EDIT_USER_FAILURE, err });
        swal(response?.data?.message, {
            icon: "error",
            timer: 4000,
            button: false,
        });
        dispatch(fetchCustomer(currentPage));
        return responseModel(response?.status, response?.data)
    }
};

export const singleUserDetails = (id, data) => async (dispatch, getState) => {
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };
    dispatch({ type: userActionTypes.SINGLE_USER_REQUEST });
    try {
        let response = await axios.get(`/users/getUserForAdmin/${id}`, {
            headers,
        });

        dispatch({
            type: userActionTypes.SINGLE_USER_SUCCESS,
            payload: response.data,
        });
    } catch (err) {
        dispatch({ type: userActionTypes.SINGLE_USER_FAILURE, err });
    }
};


export const fetchLocalkings = (setFilterCount) => async (dispatch, getState) => {
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };
    dispatch({ type: userActionTypes.LOCALKING_LIST_REQUEST });
    try {
        const response = await axios.get(
            `/users?currentRole=LocalKing&page=${setFilterCount}&limit=10`,
            {
                headers,
            }
        );
        dispatch({
            type: userActionTypes.LOCALKING_LIST_SUCCESS,
            payload: response.data,
        });

    } catch (err) {
        dispatch({ type: userActionTypes.LOCALKING_LIST_FAILURE, err });
    }
};

export const fetchLocalKingPaymentList = (filter, pageCount) => async (dispatch, getState) => {
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };
    dispatch({ type: userActionTypes.LOCKALKING_PAYOUT_LIST_REQUEST });
    try {

        const response = await axios.get(`/users/getLocalkingforpayout?sort=-payoutRequestAt&page=${pageCount}&limit=10`, {
            headers,
        });
        dispatch({
            type: userActionTypes.LOCKALKING_PAYOUT_LIST_SUCCESS,
            payload: response.data,
        });

    } catch (err) {
        dispatch({ type: userActionTypes.LOCKALKING_PAYOUT_LIST_FAILURE, err });
    }
};

export const fetchLocalkingsFilter = (dbvalue, pageCount) => async (dispatch, getState) => {
    const value = dbvalue ? dbvalue : null;
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };
    dispatch({ type: userActionTypes.LOCALKING_FILTER_LIST_REQUEST });
    try {
        const response = await axios.get(
            `/users?currentRole=LocalKing&page=${pageCount}&limit=10&firstName=${value}`,
            {
                headers,
            }
        );

        dispatch({
            type: userActionTypes.LOCALKING_FILTER_LIST_SUCCESS,
            payload: response.data,
        });
    } catch (err) {
        dispatch({ type: userActionTypes.LOCALKING_FILTER_LIST_FAILURE, err });
    }
};

export const createPayouts = (data) => async (dispatch, getState) => {
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };
    dispatch({ type: userActionTypes.PAYOUT_LIST_REQUEST });
    try {
        const response = await axios.post("/payment/payouts", data, {
            headers,
        });

        dispatch({
            type: userActionTypes.PAYOUT_LIST_SUCCESS,
            payload: response.data.data,
        });

        dispatch(fetchLocalKingPaymentList());
        swal(sweetAlert.PAYOUT_SUCCESS, {
            icon: "success",
            timer: 4000,
            button: false,
        });
    } catch (err) {
        dispatch({ type: userActionTypes.PAYOUT_LIST_FAILURE, err });
        swal(sweetAlert.PAYOUT_FAILURE, {
            icon: "error",
            timer: 4000,
            button: false,
        });
    }
};

export const fetchLocalkingsForMapList = () => async (dispatch, getState) => {
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };
    dispatch({ type: userActionTypes.LOCALKING_LIST_FOR_MAP_LIST_REQUEST });
    try {
        const response = await axios.get(
            `/users?currentRole=LocalKing`,
            {
                headers,
            }
        );
        dispatch({
            type: userActionTypes.LOCALKING_LIST_FOR_MAP_LIST_SUCCESS,
            payload: response.data,
        });

    } catch (err) {
        dispatch({ type: userActionTypes.LOCALKING_LIST_FOR_MAP_LIST_FAILURE, err });
    }
};

export const fetchOrder = (filter, filterCountNumber, dbvalue) => async (dispatch, getState) => {
    console.log("action paly",dbvalue)
    const value = dbvalue ? dbvalue : null;
    const sortByValue = "-createdAt";
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };
    dispatch({ type: userActionTypes.ORDER_LIST_REQUEST });
    try {
        const response = await axios.get(`/order/adminOrder?dateFilterType=${filter}&page=${filterCountNumber}&limit=10&Search=${filter ? null : value}`,
            {
                headers,
            }
        );
        dispatch({
            type: userActionTypes.ORDER_LIST_SUCCESS,
            payload: response.data,
        });
    } catch (err) {
        dispatch({ type: userActionTypes.ORDER_LIST_FAILURE, err });
    }
};

export const fetchFilterOrder = (dbvalue) => async (dispatch, getState) => {
    // const token = getState().loginReducer.currentUser.token;
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };
    dispatch({ type: userActionTypes.ORDER_LIST_REQUEST });
    try {
        const response = await axios.get(`/order/adminOrder?sort=-from&page=1&limit=10&Search=${dbvalue}`, { headers }
        );
        dispatch({
            type: userActionTypes.ORDER_LIST_SUCCESS,
            payload: response.data,
        });
    } catch (err) {
        dispatch({ type: userActionTypes.ORDER_LIST_FAILURE, err });
    }
};

export const recentFiveOrders = () => async (dispatch, getState) => {
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };
    dispatch({ type: userActionTypes.ORDER_FIVE_REQUEST });
    try {
        const response = await axios.get(`/order/getRecentFiveOrders?limit=5`, { headers });
        dispatch({
            type: userActionTypes.ORDER_FIVE_SUCCESS,
            payload: response.data.data,
        });
    } catch (err) {
        dispatch({ type: userActionTypes.ORDER_FIVE_FAILURE, err });
    }
};

export const cancelledOrder = () => async (dispatch, getState) => {
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };
    dispatch({ type: userActionTypes.ORDER_CANCEL_REQUEST });
    try {
        const response = await axios.get(`order/adminOrder?Search=${null}`, {
            headers,
        });
        dispatch({
            type: userActionTypes.ORDER_CANCEL_SUCCESS,
            payload: response.data.data,
        });
    } catch (err) {
        dispatch({ type: userActionTypes.ORDER_CANCEL_FAILURE, err });
    }
};

export const fetchServices = () => async (dispatch, getState) => {
    // const token = getState().loginReducer.currentUser.token;
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };
    dispatch({ type: userActionTypes.GET_SERVICE_REQUEST });
    try {
        const response = await axios.get("/service", {
            headers,
        });
        dispatch({
            type: userActionTypes.GET_SERVICE_SUCCESS,
            payload: response.data.data,
        });

    } catch (err) {
        dispatch({ type: userActionTypes.GET_SERVICE_FAILURE, err });
    }
};

export const schedulePayout = (data) => async (dispatch, getState) => {

    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };
    dispatch({ type: userActionTypes.SCHEDULE_PAYOUT_LIST_REQUEST });
    try {
        const response = await axios.post("/payment/payout-schedule", data, {
            headers,
        });
        dispatch({
            type: userActionTypes.SCHEDULE_PAYOUT_LIST_SUCCESS,
            payload: response.data,
        });
        // dispatch(fetchLocalkings());
        dispatch(fetchLocalKingPaymentList());
        swal(sweetAlert.SCHEDULE_SUCCESS, {
            icon: "success",
            timer: 4000,
            button: false,
        });
    } catch (err) {
        dispatch({ type: userActionTypes.SCHEDULE_PAYOUT_LIST_FAILURE, err });
        swal(sweetAlert.SCHEDULE_FAILURE, {
            icon: "error",
            timer: 4000,
            button: false,
        });
    }
};

export const getSchedulePayoutData = (data) => async (dispatch, getState) => {

    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };
    dispatch({ type: userActionTypes.GET_SCHEDULE_PAYOUT_LIST_REQUEST });
    try {
        const response = await axios.get("/payment/payout-schedule", {
            headers,
        });
        dispatch({
            type: userActionTypes.GET_SCHEDULE_PAYOUT_LIST_SUCCESS,
            payload: response.data,
        });

    } catch (err) {
        dispatch({ type: userActionTypes.GET_SCHEDULE_PAYOUT_LIST_FAILURE, err });
    }
};

export const OrderReview = (id) => async (dispatch, getState) => {
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };
    dispatch({ type: userActionTypes.ORDER_REVIEW_REQUEST });
    try {
        const response = await axios.get(`review/order-review/${id}`, {
            headers,
        });
        dispatch({
            type: userActionTypes.ORDER_REVIEW_SUCCESS,
            payload: response.data,
        });
    } catch (err) {
        dispatch({ type: userActionTypes.ORDER_REVIEW_FAILURE, err });
    }
};

export const OrderReviewDelete = (id) => async (dispatch, getState) => {
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };
    dispatch({ type: userActionTypes.ORDER_REVIEW_DELETE_REQUEST });
    try {
        const response = await axios.delete(`review/${id}`, {
            headers,
        });
        dispatch({
            type: userActionTypes.ORDER_REVIEW_DELETE_SUCCESS,
            payload: response.data,
        });

        swal(sweetAlert.DELETE_USER_SUCCESS, {
            title: "Successfully Deleted",
            icon: "success",
            timer: 4000,
            button: false,
        });
    } catch (err) {
        dispatch({ type: userActionTypes.ORDER_REVIEW_DELETE_FAILURE, err });
    }
};

export const activeUser = (data, pageCount) => async (dispatch, getState) => {
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };
    dispatch({ type: userActionTypes.ACTIVE_USER_REQUEST });
    try {
        const response = await axios.post(`/users/changeStatus`, data, {
            headers,
        });
        dispatch({
            type: userActionTypes.ACTIVE_USER_SUCCESS,
            payload: response.data.data,
        });

        dispatch(fetchCustomer(pageCount));
        return responseModel(response.status, response.data);
    } catch (err) {
        dispatch({ type: userActionTypes.ACTIVE_USER_FAILURE, err });
        const response = axiosErrorHandler(err);
        swal(err.response.data.message, {
            icon: "error",
            timer: 2000,
            button: false,
        });
        return responseModel(response?.status, response?.data)
    }
};

export const deleteUser = (id, pageCount) => async (dispatch, getState) => {
    var form = document.createElement("div");
    form.innerHTML = `<p className="text-black font-25">Are you sure you want to <span>Delete this User</span>?</p>`;
    swal({
        content: form,
        className: "custom_delete_sweet_alert",
        buttons: {
            confirm: {
                text: "Yes, Delete",
                value: true,
                visible: true,
                className: "",
                closeModal: true,
            },
            cancel: {
                text: "No,Cancel",
                value: false,
                visible: true,
                className: "",
                closeModal: true,
            }
        },

    }).then(async function (isConfirm) {
        if (isConfirm === true) {
            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            dispatch({ type: userActionTypes.DELETE_USER_REQUEST });
            try {
                const response = await axios.delete(`/users/${id}`, {
                    headers,
                });
                dispatch({
                    type: userActionTypes.DELETE_USER_SUCCESS,
                    payload: response.data.data,
                });
                const notyf = new Notyf({
                    duration: 10000,
                    position: {
                        x: 'center',
                        y: 'top',
                    },

                    types: [
                        {
                            type: 'successs',
                            ripple: true,
                            icon: true,
                        }
                    ]
                });

                notyf.open({
                    type: 'success',
                    message: 'User Deleted Successfuly'
                });

                dispatch(fetchCustomer(pageCount));
            } catch (err) {
                dispatch({ type: userActionTypes.DELETE_USER_FAILURE, err });
                swal(sweetAlert.DELETE_USER_FAILURE, {
                    icon: "error",
                    timer: 4000,
                    button: false,
                });
            }
        }
    });
};

export const createCoupon = (data) => async (dispatch, getState) => {
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };
    dispatch({ type: userActionTypes.CREATE_COUPON_REQUEST });
    try {

        const response = await axios.post("/coupon/createcoupon", data, {
            headers,
        });

        dispatch({
            type: userActionTypes.CREATE_COUPON_SUCCESS,
            payload: response.data,
        });

        dispatch(fetchCoupon());

        const notyf = new Notyf({
            duration: 2000,
            position: {
                x: 'center',
                y: 'top',
            },
            types: [
                {
                    type: 'successs',
                    ripple: true,
                    icon: true,
                }
            ]
        });
        notyf.open({
            type: 'success',
            message: 'Coupon Added Successfuly'
        });
        return responseModel(response.status, response.data);
    } catch (err) {
        dispatch({
            type: userActionTypes.CREATE_COUPON_FAILURE,
            payload: err,
        });
        const response = axiosErrorHandler(err);

        swal(err.response.data.message, {
            icon: "error",
            timer: 4000,
            button: false,
            isloading: false,
        });
        return responseModel(response?.status, response?.data)

    }
};

export const fetchCoupon = (pageCount, dbvalue) => async (dispatch, getState) => {
    const value = dbvalue ? dbvalue : null;
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };
    dispatch({ type: userActionTypes.COUPON_LIST_REQUEST });
    try {
        const response = await axios.get(`/coupon/getAllCoupons?sort=-createdAt&page=${value ? 1 : pageCount}&limit=10&couponCode=${value}`, {
            headers,
        }
        );

        dispatch({
            type: userActionTypes.COUPON_LIST_SUCCESS,
            payload: response.data,
        });
    } catch (err) {
        dispatch({ type: userActionTypes.COUPON_LIST_FAILURE, err });
    }
};

export const deleteCoupon = (id,currentPage, couponCode) => async (dispatch, getState) => {
    const coupon = couponCode ? couponCode : null;
    var form = document.createElement("div");
    form.innerHTML = `<p className="text-black font-25">Are you sure you want to Delete <span>${coupon} Coupon</span>?</p>`;
    swal({
        content: form,
        className: "custom_delete_sweet_alert",
        buttons: {
            confirm: {
                text: "Yes, Delete",
                value: true,
                visible: true,
                className: "",
                closeModal: true,
            },
            cancel: {
                text: "No,Cancel",
                value: false,
                visible: true,
                className: "",
                closeModal: true,
            }
        },

    }).then(async function (isConfirm) {
        if (isConfirm === true) {
            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            dispatch({ type: userActionTypes.DELETE_COUPON_REQUEST });
            try {
                const response = await axios.delete(`/coupon/deletecoupon/${id}`, {
                    headers,
                });
                dispatch({
                    type: userActionTypes.DELETE_COUPON_SUCCESS,
                    payload: response.data.data,
                });

                // window.location.reload(false);
                const notyf = new Notyf({
                    duration: 30000,
                    position: {
                        x: 'center',
                        y: 'top',
                    },
                    types: [
                        {
                            type: 'successs',
                            ripple: true,
                            icon: true,
                        }
                    ]
                });
                notyf.open({
                    type: 'success',
                    message: 'Coupon Deleted Successfuly'
                });
                dispatch(fetchCoupon(currentPage,null));
            } catch (err) {
                dispatch({ type: userActionTypes.DELETE_COUPON_FAILURE, err });
                swal(sweetAlert.DELETE_COUPON_FAILURE, {
                    icon: "error",
                    timer: 4000,
                    button: false,
                });
            }
        }

    });

};

export const singleCouponDetails = (id, data) => async (dispatch, getState) => {
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };
    dispatch({ type: userActionTypes.SINGLE_COUPON_REQUEST });
    try {
        let response = await axios.get(`/coupon/editcoupon/${id}`, {
            headers,
        });
        dispatch({
            type: userActionTypes.SINGLE_COUPON_SUCCESS,
            payload: response.data,
        });
    } catch (err) {
        dispatch({ type: userActionTypes.SINGLE_COUPON_FAILURE, err });
    }
};

export const updateCoupon = (id, data,currentPage) => async (dispatch, getState) => {
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };
    dispatch({ type: userActionTypes.UPDATE_COUPON_REQUEST });
    try {
        let response = await axios.patch(`coupon/updatecoupon/${id}`, data, {
            headers,
        });
        dispatch({
            type: userActionTypes.UPDATE_COUPON_SUCCESS,
            payload: response.data,
        });

        dispatch(fetchCoupon());

        const notyf = new Notyf({
            duration: 2000,
            position: {
                x: 'center',
                y: 'top',
            },
            types: [
                {
                    type: 'successs',
                    ripple: true,
                    icon: true,
                }
            ]
        });
        notyf.open({
            type: 'success',
            message: 'Coupon Edited Successfuly'
        });
        return responseModel(response.status, response.data);
    } catch (err) {
        const response = axiosErrorHandler(err);
        swal(response?.data?.message, {
            icon: "error",
            timer: 4000,
            button: false,
        });
        return responseModel(response?.status, response?.data)
    }
};

export const updateServiceAmount = (data) => async (dispatch, getState) => {
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };
    dispatch({ type: userActionTypes.UPDATE_COUPON_REQUEST });
    try {
        let response = await axios.post(`/service/updateServiceAmount`, data, {
            headers,
        });
        swal("Service Amount Update Successfully!", {
            buttons: false,
            timer: 2000,
            icon: "success",
        });
        dispatch({
            type: userActionTypes.UPDATE_COUPON_SUCCESS,
            payload: response.data,
        });
    } catch (err) {
        swal("something went wrong!", {
            buttons: false,
            timer: 2000,
            icon: "error",
        });
        dispatch({ type: userActionTypes.UPDATE_COUPON_FAILURE, err });
    }
};

export const getServicesAmount = (pageCount) => async (dispatch, getState) => {
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };
    dispatch({ type: userActionTypes.GET_SERVICES_AMOUNT_REQUEST });
    try {
        const response = await axios.get(`/service/getAllServiceForAdmin`, { headers, });
        dispatch({
            type: userActionTypes.GET_SERVICES_AMOUNT_SUCCESS,
            payload: response.data,
        });
    } catch (err) {
        dispatch({ type: userActionTypes.GET_SERVICES_AMOUNT_FAILURE, err });
    }
};

export const paymentmethodSetting = (data) => async (dispatch, getState) => {
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };
    dispatch({ type: userActionTypes.UPDATE_COUPON_REQUEST });
    try {
        let response = await axios.post(`/admin/createPaymentMethod`, data, {
            headers,
        });
        swal("Payment Method Update Successfully!", {
            buttons: false,
            timer: 2000,
            icon: "success",
        });
        dispatch({
            type: userActionTypes.UPDATE_COUPON_SUCCESS,
            payload: response.data,
        });
    } catch (err) {
        swal("something went wrong!", {
            buttons: false,
            timer: 2000,
            icon: "error",
        });
        dispatch({ type: userActionTypes.UPDATE_COUPON_FAILURE, err });
    }
};

export const createWalkInScreen = (data) => async (dispatch, getState) => {
    const token = localStorage.getItem("token");
    const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
    };
    dispatch({ type: userActionTypes.CREATE_WALK_IN_SCREEN_REQUEST });
    try {
        const response = await axios.post("/walkinscreen/create", data, {
            headers,
        });
        dispatch({
            type: userActionTypes.CREATE_WALK_IN_SCREEN_SUCCESS,
            payload: response.data,
        });
        swal(sweetAlert.CREATE_WALK_IN_SCREEN_SUCCESS, {
            icon: "success",
            timer: 4000,
            button: false,
        });
        return responseModel(response.status, response.data);
    } catch (error) {
        dispatch({ type: userActionTypes.CREATE_WALK_IN_SCREEN_FAILURE, error });
        const response = axiosErrorHandler(error);

        const usingSplit = error.response.data.message.split(':');
        swal(usingSplit['2'], {
            icon: "error",
            timer: 4000,
            button: false,
        });
        return responseModel(response?.status, response?.data)
    }
};

export const fetchWalkInScreens = (data, pageCount, dbvalue) => async (dispatch, getState) => {
    // add search param
    const value = dbvalue ? dbvalue : null;
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };
    dispatch({ type: userActionTypes.FETC_WALK_IN_SCREEN_REQUEST });
    try {
        const response = await axios.get(`/walkinscreen/get?sort=ScreenOrder&page=${pageCount}&limit=10&Search=${value}`, { headers, });
        dispatch({
            type: userActionTypes.FETC_WALK_IN_SCREEN_SUCCESS,
            payload: response.data,
        });
    } catch (err) {
        dispatch({ type: userActionTypes.FETC_WALK_IN_SCREEN_FAILURE, err });
    }
};

export const deleteWalkInScreens = (id, currentPage) => async (dispatch, getState) => {
    var form = document.createElement("div");
    form.innerHTML = `<p className="text-black font-25">Are you sure you want to <span>Delete this Walk-in screen</span>?</p>`;
    swal({
        content: form,
        className: "custom_delete_sweet_alert",
        buttons: {
            confirm: {
                text: "Yes, Delete",
                value: true,
                visible: true,
                className: "",
                closeModal: true,
            },
            cancel: {
                text: "No,Cancel",
                value: false,
                visible: true,
                className: "",
                closeModal: true,
            }
        },

    }).then(async function (isConfirm) {

        if (isConfirm === true) {
            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            dispatch({ type: userActionTypes.DELETE_WALK_IN_SCREEN_REQUEST });
            try {
                const response = await axios.delete(`/walkinscreen/delete/${id}`, {
                    headers,
                });
                dispatch({
                    type: userActionTypes.DELETE_WALK_IN_SCREEN_SUCCESS,
                    payload: response.data.data,
                });
                swal(sweetAlert.DELETE_WALK_IN_SCREEN_SUCCESS_MSG, {
                    icon: "success",
                    timer: 4000,
                    button: false,
                });
                dispatch(fetchWalkInScreens(currentPage));
            } catch (err) {
                dispatch({ type: userActionTypes.DELETE_WALK_IN_SCREEN_FAILURE, err });
                swal(sweetAlert.DELETE_WALK_IN_SCREEN_FAILURE_MSG, {
                    icon: "error",
                    timer: 4000,
                    button: false,
                });
                dispatch(fetchWalkInScreens(currentPage));
            }
        }
    });

};

export const fetchSingleWalkInScreenDetails = (id, data) => async (dispatch, getState) => {
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };
    dispatch({ type: userActionTypes.FETCH_SINGLE_WALK_IN_SCREEN_REQUEST });
    try {
        let response = await axios.get(`/walkinscreen/getSingle/${id}`, {
            headers,
        });
        dispatch({
            type: userActionTypes.FETCH_SINGLE_WALK_IN_SCREEN_SUCCESS,
            payload: response.data,
        });
    } catch (err) {
        dispatch({ type: userActionTypes.FETCH_SINGLE_WALK_IN_SCREEN_FAILURE, err });
    }
};

export const updateWalkInScreen = (id, data) => async (dispatch, getState) => {

    const token = localStorage.getItem("token");
    const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>'
    };

    dispatch({ type: userActionTypes.UPDATE_WALK_IN_SCREEN_REQUEST });
    try {
        let response = await axios.patch(`/walkinscreen/update/${id}`, data, {
            headers,
        });

        dispatch({
            type: userActionTypes.UPDATE_WALK_IN_SCREEN_SUCCESS,
            payload: response.data,
        });

        swal(sweetAlert.UPDATE_WALK_IN_SCREEN_SUCCESS_MSG, {
            icon: "success",
            timer: 4000,
            button: false,
        });
        dispatch(fetchWalkInScreens());
        return responseModel(response.status, response.data);
    } catch (err) {
        // swal(sweetAlert.UPDATE_WALK_IN_SCREEN_FAILURE_MSG, {
        //     icon: "error",
        //     timer: 4000,
        //     button: false,
        // });
        dispatch({ type: userActionTypes.UPDATE_WALK_IN_SCREEN_FAILURE, err });
        const response = axiosErrorHandler(err);
        swal(err.response.data.message, {
            icon: "error",
            timer: 4000,
            button: false,
            isloading: false,
        });
        return responseModel(response?.status, response?.data)
    }
};

export const getUnReadNotification = ( filterCountNumber, dbvalue) => async (dispatch, getState) => {
    // add search param
    const value = dbvalue ? dbvalue : null;
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}`, };
    dispatch({ type: userActionTypes.GET_UNREAD_NOTIFICATION_REQUEST });
    try {
        let response = await axios.get(`/notification/admin?sort=-createdAt&type=bell&isRead=false&page=${filterCountNumber}&limit=10&Search=${value}`, { headers, });
        dispatch({
            type: userActionTypes.GET_UNREAD_NOTIFICATION_SUCCESS,
            payload: response.data,
        });
    } catch (err) {
        dispatch({ type: userActionTypes.GET_UNREAD_NOTIFICATION_FAILURE, err });
    }
};


export const getReadNotification = (filterCountNumber, dbvalue) => async (dispatch, getState) => {
    const value = dbvalue ? dbvalue : null;
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}`, };
    dispatch({ type: userActionTypes.GET_READ_NOTIFICATION_REQUEST });
    try {
        let response = await axios.get(`/notification/admin?sort=-createdAt&isRead=true&page=${filterCountNumber}&limit=10&Search=${value}`, { headers, });
        dispatch({
            type: userActionTypes.GET_READ_NOTIFICATION_SUCCESS,
            payload: response.data,
        });
    } catch (err) {
        dispatch({ type: userActionTypes.GET_READ_NOTIFICATION_FAILURE, err });
    }
};


export const readNotification = (id) => async (dispatch, getState) => {
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}`, };
    dispatch({ type: userActionTypes.READ_NOTIFICATION_REQUEST });
    try {
        let response = await axios.get(`notification/${id}`, { headers, });
        dispatch({
            type: userActionTypes.READ_NOTIFICATION_SUCCESS,
            payload: response.data,
        });
        getUnReadNotification(1,null)
    } catch (err) {
        dispatch({ type: userActionTypes.READ_NOTIFICATION_FAILURE, err });
    }
};

export const getChat = () => async (id) => {
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };
    // dispatch({ type: userActionTypes.GET_CHAT_DOWNLOAD_REQUEST });
    try {
        const response = await axios.get(`conversation/getChat/${id}`, { headers });
        return response.data.data
        // dispatch({
        //     type: userActionTypes.GET_CHAT_DOWNLOAD_SUCCESS,
        //     payload: response.data.data,
        // });
    } catch (err) {
        // dispatch({ type: userActionTypes.GET_CHAT_DOWNLOAD_FAILUER, err });
        console.log(err)
    }
};