import { Box, CircularProgress, makeStyles } from "@material-ui/core";
import Layout from "../layout";
import Table from "../components/table/Table";
import { useDispatch, useSelector } from "react-redux";
import ReactLoading from "react-loading";
import { useCallback, useEffect } from "react";
import { fetchFilterOrder, fetchOrder } from "../redux/user/action";
import { Grid } from "@mui/material";
import { useState } from "react";
import debounce from "lodash.debounce";

const useStyles = makeStyles({
  component: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontWeight: "bold",
    fontSize: "25px",
    lineHeight: "38px",
    color: "#003AD2",
    marginBottom: "2em",
  },
});

const tableHead = [
  { id: 1, title: "S.No" },
  { id: 3, title: "Customer" },
  { id: 4, title: "Localking" },
  { id: 5, title: "From" },
  { id: 6, title: "To" },
  { id: 7, title: "Packages" },
  { id: 8, title: "Service" },
  { id: 9, title: "Sub-Service" },
  { id: 10, title: "Status" },
  { id: 11, title: "" },
];

export default function Transaction() {
  const classes = useStyles();
  const [data, setSearchData] = useState(null);
  const [dbvalue, setDbvalue] = useState("");

  const transactions = useSelector(({ orderReducer }) => orderReducer);
  const listTransaction = transactions?.items;

  const FilterTransactioncount =
    transactions?.items?.count == undefined ? 0 : transactions?.items?.count;
  console.log("TableFilterCoun", listTransaction?.data?.count);

  const [filter, setFilter] = useState(null);
  const [filterCountNumber, setFilterCountNumber] = useState(1);
  const Dispatch = useDispatch();

  const debounsedSaved = useCallback(
    debounce((data) => setDbvalue(data), 1000),
    []
  );
  debounsedSaved(data);

  const setfilterVal = (val) => {
    setFilter(val);
  };

  const setFilterCount = (count) => {
    setFilterCountNumber(count);
  };

  useEffect(() => {
    if (filterCountNumber >= 1 && filter != null) {
      setSearchData(dbvalue);
      Dispatch(fetchOrder(filter, setFilterCountNumber(1), dbvalue));
    }
  }, [filter]);

  useEffect(() => {
    if (dbvalue != null) {
      setSearchData(dbvalue);
      Dispatch(fetchOrder(filter, setFilterCountNumber(1), dbvalue));
    }
    // if (dbvalue == "" && filterCountNumber >= 1) {
    //   setFilterCountNumber(1)
    //   Dispatch(fetchOrder(filter, filterCountNumber, dbvalue));
    // }
  }, [dbvalue]);

  useEffect(() => {
    Dispatch(fetchOrder(filter, filterCountNumber, dbvalue));
  }, [filter, filterCountNumber, dbvalue]);

  return (
    <>
      <Layout>
        <Box className={classes.component}>
          <Box>
            {transactions.loading == true && (
              <Grid
                container
                justifyContent="center"
                style={{
                  height: "125vh",
                  width: "70%",
                  position: "absolute",
                }}
                alignItems="center"
              >
                <CircularProgress />
              </Grid>
            )}
            <Table
              name="Transaction"
              tableTitle="Transaction"
              tableHead={tableHead}
              tableBody={listTransaction}
              paginate={true}
              type="transaction"
              TableFilterCoun={FilterTransactioncount}
              item="false"
              setSearchData={setSearchData}
              setfilterVal={setfilterVal}
              filtercount={listTransaction?.data || []}
              setFilterCount={setFilterCount}
              dbvalue={dbvalue || ""}
              filtervalue={filter || null}
              filterCountNumber={data}
            />
          </Box>
        </Box>
      </Layout>
    </>
  );
}
