import React, { useEffect, useState } from "react";
import CustomButton from "../custom/Button";
import NavBar from "../custom/navBar";
import InputField from "../custom/Input";
import { Grid, makeStyles } from "@material-ui/core";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { singleCouponDetails, updateCoupon } from "../../redux/user/action";
import { formatDateAndTimeForCoupon } from "../custom/formatDateAndTime";
import swal from "sweetalert";

export default function EditCouponForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const register = useSelector(({ EditUserReducer }) => EditUserReducer);

  const useStyles = makeStyles({
    formControl: {
      border: "1px solid #E6EAF0 !important",
      height: "48px",
      borderRadius: "5px",
      padding: "0rem 1rem",
      width: "100%",
    },
  });
  const classes = useStyles();
  const getCouponData = useSelector(
    ({ getSingleCouponDetailsReducer }) => getSingleCouponDetailsReducer
  );

  const { id } = state;
  const { currentPage } = state;
  const couponData = getCouponData?.items?.data;

  const [getInputField, setInputField] = useState({});

  useEffect(() => {
    dispatch(singleCouponDetails(id));
  }, [id]);

  useEffect(() => {
    setInputField({
      couponCode: couponData?.couponCode,
      couponUses: couponData?.couponUses,
      couponAmount: couponData?.couponAmount,
      startDate: couponData?.startDate,
      expireDate: couponData?.expireDate,
      amountType: couponData?.amountType,
      service: couponData?.service,
      serviceType: couponData?.serviceType,
    });
  }, [getCouponData]);

  const InputsObj = [
    {
      id: 2,
      label: "Coupon Name/Coupon Code",
      palceHolder: "Enter coupon code",
      type: "text",
      name: "couponCode",
      requied: "requied",
      value:
        getInputField?.couponCode === undefined
          ? ""
          : getInputField?.couponCode,
    },

    {
      id: 3,
      label: "Usage",
      palceHolder: "Enter number of times the coupon can be used",
      type: "number",
      name: "couponUses",
      requied: "requied",
      value:
        getInputField?.couponUses === undefined
          ? ""
          : getInputField?.couponUses,
    },

    {
      id: 5,
      label: "From",
      palceHolder: "DD/MM/YYYY",
      type: "datetime-local",
      name: "startDate",
      requied: "requied",
      value:
        getInputField?.startDate === undefined
          ? ""
          : formatDateAndTimeForCoupon(getInputField?.startDate),
      min: new Date().toISOString().slice(0, 16),
    },

    {
      id: 4,
      label: "To",
      palceHolder: "DD/MM/YYYY",
      type: "datetime-local",
      name: "expireDate",
      requied: "requied",
      value:
        getInputField?.expireDate === undefined
          ? ""
          : formatDateAndTimeForCoupon(getInputField?.expireDate),
      min:
        formatDateAndTimeForCoupon(getInputField?.startDate) === ""
          ? new Date().toISOString().slice(0, 16)
          : formatDateAndTimeForCoupon(getInputField?.startDate),
    },

    {
      id: 6,
      label: "Service",
      type: "radio",
      name: "service",
      requied: "requied",
      value: getInputField?.service === undefined ? "" : getInputField?.service,
      option: [
        {
          id: 1,
          value: "Return",
          name: "Return",
          checked: getInputField?.service === "Return" ? true : false,
        },
        {
          id: 2,
          value: "Delivery",
          name: "Delivery",
          checked: getInputField?.service === "Delivery" ? true : false,
        },
      ],
    },

    {
      id: 7,
      label: "Service Type",
      type: "radio",
      name: "serviceType",
      requied: "requied",
      value:
        getInputField?.serviceType === undefined
          ? ""
          : getInputField?.serviceType,
      option: [
        {
          id: 1,
          value: "Bring",
          name: "Bring",
          checked: getInputField?.serviceType === "Bring" ? true : false,
        },
        {
          id: 2,
          value: "Fetch",
          name: "Fetch",
          checked: getInputField?.serviceType === "Fetch" ? true : false,
        },
      ],
    },

    {
      id: 7,
      label: "Type of coupon",
      palceHolder: "fixed",
      type: "select",
      name: "amountType",
      requied: "requied",
      value:
        getInputField?.amountType === undefined
          ? ""
          : getInputField?.amountType,
      option: [
        { id: 1, value: 1, name: "Fixed" },
        { id: 2, value: 2, name: "Percentage" },
      ],
    },

    {
      id: 8,
      label: "Amount",
      palceHolder: "01.00 €",
      type: "number",
      name: "couponAmount",
      requied: "requied",
      value:
        getInputField?.couponAmount === undefined
          ? ""
          : getInputField?.couponAmount,
    },
  ];

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputField({ ...getInputField, [name]: value });
  };

  const handleclick = async (e) => {
    if (getInputField.couponAmount.length > 4) {
      swal("Error!", "Please Enter Limited coupon amount!", "error");
      return;
    }

    if (!getInputField.couponCode) {
      swal("Error!", "Coupon Name/Coupon Code field is required!", "error");
      return;
    }

    if (!getInputField.couponUses) {
      swal("Error!", "Usage field is required!", "error");
      return;
    }

    if (!getInputField.couponAmount) {
      swal("Error!", "Coupon amount field is required!", "error");
      return;
    }

    if (!getInputField.startDate) {
      swal("Error!", "Coupon start date field is required!", "error");
      return;
    }

    if (!getInputField.expireDate) {
      swal("Error!", "Expire date field is required!", "error");
      return;
    }

    if (!getInputField.service) {
      swal("Error!", "Service field is required!", "error");
      return;
    }

    if (!getInputField.serviceType) {
      swal("Error!", "Service type field is required!", "error");
      return;
    }

    if (!getInputField.amountType) {
      swal("Error!", "Amount type field is required!", "error");
      return;
    }

    if (getInputField.startDate > getInputField.expireDate) {
      swal("Error!", "Please fill the Expire Date correctly.", "error");
      return ;
    }

    e.preventDefault();
    const responseData = await dispatch(
      updateCoupon(id, getInputField, currentPage)
    );
    if (responseData.status === 200) {
      navigate("/coupon-list", { state: { currentPage: currentPage } });
    }
  };

  const location = useLocation();

  return (
    <>
      {register.loading && (
        <Grid
          container
          justifyContent="center"
          style={{
            height: "100vh",
            width: "100%",
            position: "absolute",
          }}
          alignItems="center"
        ></Grid>
      )}
      <NavBar title="Coupons" url={location.pathname} />

      <div className="formBox">
        <h2
          className="mb-5 font-29 font-weight-700"
          style={{ color: "#1F4173" }}
        >
          {" "}
          Edit coupon{" "}
        </h2>
        <div className="row">
          <div className="col-md-12">
            {InputsObj.map((val, index) => {
              const cl = `col-lg-${
                val.type === "datetime-local" ? 4 : 8
              } col-xxl-${val.type === "datetime-local" ? 3 : 6} col-md-${
                val.type === "datetime-local" ? 6 : 6
              } col-sm-12 mb-4`;
              return (
                <>
                  {index === 2 ? (
                    <div className={`${cl}`}>
                      <div className="mb-4 mt-3">
                        <label className="form-label d-block">Validity</label>
                      </div>
                    </div>
                  ) : null}
                  <div
                    className={`${cl} ${
                      val.type === "datetime-local"
                        ? "d-lg-inline-block d-md-block"
                        : ""
                    } ${index === 2 ? "pe-1" : ""} ${
                      index === 3 ? "ps-1" : ""
                    }`}
                    key={val.id}
                  >
                    <InputField
                      id={val.id}
                      label={val.label}
                      type={val.type}
                      placeholder={val.palceHolder}
                      option={val.option}
                      handleChange={handleChange}
                      name={val.name}
                      value={val.value}
                      requied={val.requied}
                      classStyle={classes}
                      className="custom-radio-btn"
                      minDate={val.min}
                      maxDate={val.max}
                    />
                  </div>
                </>
              );
            })}
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-md-12 col-lg-12 col-xl-8 col-xxl-6">
            <div className="row">
              <div className="col-md-6 col-lg-5 col-xl-7 col-sm-6">
                <CustomButton
                  className={`btn font-weight-700 pb-2 pt-2 text-white`}
                  type="submit"
                  style={{
                    background: "#003AD2",
                    borderRadius: "8px",
                    width: "290px",
                    height: "55px",
                    fontSize: "26px",
                  }}
                  onClick={handleclick}
                >
                  {" "}
                  Save
                </CustomButton>
              </div>

              <div className="col-md-6 col-lg-4 col-xl-4 col-sm-6">
                <CustomButton
                  style={{
                    color: "#1F4173",
                    width: "140px !important ",
                    textTransform: "capitalize",
                    height: "55px",
                    fontWeight: 700,
                    fontSize: "26px",
                  }}
                  onClick={() =>
                    navigate("/coupon-list", {
                      state: { currentPage: currentPage },
                    })
                  }
                >
                  Cancel
                </CustomButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
