import { Button } from "@material-ui/core";
import { Rating } from "@mui/material";
import { useLocation } from "react-router-dom";
import CustomButton from "../components/custom/Button";
import NavBar from "../components/custom/navBar";
import Layout from "../layout";
import VisibilityOffIcon from "../assets/images/VisibilityOffIcon.png";
import DeleteIcon from "../assets/images/DeleteIcon.png";
import FileDownloadIcon from "../assets/images/FileDownloadIcon.png";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OrderReview, OrderReviewDelete } from "../redux/user/action";
import swal from "sweetalert";
import unfilledRating from "../assets/images/unfilledRating.png";
import filledRating from "../assets/images/Rating.png";
import { makeStyles } from "@material-ui/core";
import {
  tConvert,
  formatDateAndTime,
} from "../components/custom/formatDateAndTime";

export default function ViewTranaction() {
  const useStyles = makeStyles({
    customRatingIcon: {
      "& span": {
        marginRight: "5px",
      },
    },
  });
  const classes = useStyles();
  const location = useLocation();
  const [hideCustomer, setHideCustomer] = useState(false);
  const [hideLocalKing, setHideLocalKing] = useState(false);
  const [deleteCustomer, setDeleteCustomer] = useState(false);
  const [packageNameList, setPackageNameList] = useState({});
  // const[currentRole,setCurrentRole]=useState()
  // API Call............
  const OrderReviewdata = useSelector(
    ({ OrderReviewReducer }) => OrderReviewReducer
  );
  const OrderReviewDeleteReducer = useSelector(
    ({ OrderReviewDeleteReducer }) => OrderReviewDeleteReducer
  );
  const currentRole = OrderReviewdata?.items?.data;

  // const uniq = [...new Set(currentRole)];
  const transactions = useSelector(({ orderReducer }) => orderReducer);
  const listTransaction = transactions?.items;
  const dispatch = useDispatch();

  useEffect(() => {
    const condition = localStorage.getItem("mykey");
    setHideCustomer(condition);
    setHideLocalKing(condition);
    dispatch(OrderReview(location?.state));
    getPackageNameList();
  }, [OrderReviewDeleteReducer]);

  // console.log("data:",listTransaction.data[0].localKing)

  const getPackageNameList = () => {
    const packageName = transactiondata[0]?.package.map((name) => {
      return name.service.serviceName;
    });
    const result = {};
    for (let pName of packageName) {
      result[pName] = result[pName] + 1 || 1;
    }
    setPackageNameList(result);
  };

  console.log("packageNameList", packageNameList);

  localStorage.setItem("mykey", hideCustomer);

  const handleVisibiltyCustomer = () => {
    var form = document.createElement("div");
    form.innerHTML = `<p className="text-black font-25">Are you sure you want to  <span>${
      !hideCustomer ? "Hide" : "View"
    } This Review And Rating?</span>?</p>`;
    swal({
      content: form,
      className: "custom_delete_sweet_alert",
      buttons: {
        confirm: {
          text: "Yes",
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: "No",
          value: false,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
    }).then(function (isConfirm) {
      if (isConfirm === true) {
        setHideCustomer(!hideCustomer);
      }
    });
  };

  const handleVisibiltyLocakhing = () => {
    var form = document.createElement("div");
    form.innerHTML = `<p className="text-black font-25">Are you sure you want to  <span>${
      !hideLocalKing ? "Hide" : "View"
    } This Review And Rating?</span>?</p>`;
    swal({
      content: form,
      className: "custom_delete_sweet_alert",
      buttons: {
        confirm: {
          text: "Yes",
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: "No",
          value: false,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
    }).then(function (isConfirm) {
      if (isConfirm === true) {
        setHideLocalKing(!hideLocalKing);
      }
    });
  };

  const handleDelete = (id) => {
    var form = document.createElement("div");
    form.innerHTML = `<p className="text-black font-25">Are you sure you want to <span>Delete This Review And Rating</span>?</p>`;
    swal({
      content: form,
      className: "custom_delete_sweet_alert",
      buttons: {
        confirm: {
          text: "Yes, Delete",
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: "No,Cancel",
          value: false,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
    }).then(function (isConfirm) {
      if (isConfirm === true) {
        dispatch(OrderReviewDelete(id));
      }
    });

    setDeleteCustomer(deleteCustomer);
  };

  const InsertValueInTransactionTableNameColumn = (date, time) => {
    return (
      <>
        <span className="mb-0 mr">{date}</span>
        <span className="mb-0"> - </span>
        <span className="mb-0">{time}</span>
      </>
    );
  };

  const filterTransactionById = (transaction) => {
    var newObj = transaction.data.filter(function (el) {
      return el.id === location?.state;
    });
    return newObj;
  };

  const transactionById = (listTransaction) => {
    var newObj = listTransaction.data.filter(function (el) {
      return el.id === location?.state;
    });
    return newObj;
  };

  const data = transactionById(listTransaction);
  // console.log("data:", data[0]);
  // console.log("data>>:", data[0].user.currentRole);
  // console.log("data<<:", data[0].localKing.currentRole);

  const transactiondata = filterTransactionById(listTransaction);

  return (
    <Layout>
      <NavBar title="Transaction" url={location?.pathname} />
      <div
        style={{
          boxShadow: " 0px 4px 90px rgba(163, 171, 185, 0.24)",
          borderRadius: "15px",
        }}
      >
        <div style={{ padding: "40px" }}>
          <div
            style={{
              display: "flex",
              justifyContent:"space-between",
              color: " #1F4173",
            }}
          >
            <h3 className="font-29 mb-3 font-weight-700">
              Transaction Details
            </h3>
            <div>
              <CustomButton
                style={{
                  width: "120px",
                  height: "30px",
                  fontWeight: 500,
                  background: "rgb(41, 204, 151)",
                }}
              >
                Completed
              </CustomButton>
            </div>
          </div>

          <div style={{ padding: "20px 5px" }}>
            <div style={{ display: "flex" }}>
              <p style={{ color: "#8E8E8E", width: "160px" }}>Transaction ID</p>
              :
              <p style={{ width: "250px", paddingLeft: "50px" }}>
                {transactiondata[0]?.deliveryUniqueCode}
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ color: "#8E8E8E", width: "160px" }}>From</p>:
              <p style={{ width: "250px", paddingLeft: "50px" }}>
                {transactiondata[0]?.service === "Delivery"
                  ? formatDateAndTime(transactiondata[0]?.from, "date")
                  : InsertValueInTransactionTableNameColumn(
                      formatDateAndTime(transactiondata[0]?.date, "date"),
                      tConvert(transactiondata[0]?.from)
                    )}
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ color: "#8E8E8E", width: "160px" }}>To</p>:
              <p style={{ width: "250px", paddingLeft: "50px" }}>
                {transactiondata[0]?.service === "Delivery"
                  ? formatDateAndTime(transactiondata[0]?.to, "date")
                  : InsertValueInTransactionTableNameColumn(
                      formatDateAndTime(transactiondata[0]?.date, "date"),
                      tConvert(transactiondata[0]?.to)
                    )}
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ color: "#8E8E8E", width: "160px" }}>Service</p>:
              <p style={{ width: "250px", paddingLeft: "50px" }}>
                {transactiondata[0]?.service}
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ color: "#8E8E8E", width: "160px" }}>Sub-Service</p>:
              <p style={{ width: "250px", paddingLeft: "50px" }}>
                {transactiondata[0]?.subService}
              </p>
            </div>

            <div style={{ display: "flex" }}>
              <p style={{ color: "#8E8E8E", width: "160px", display: "flex" }}>
                Packages
              </p>
              :
              <div style={{width: "250px", paddingLeft: "50px"}}>
                <div>
                  {Object.keys(packageNameList).map((name) => (
                    <>
                      <p style={{ width: "auto" }}>
                        {`${name} `}
                        {`${
                          packageNameList[name] < 10
                            ? "(0" + packageNameList[name] + ")"
                            : `(${packageNameList[name]})`
                        }`}
                      </p>
                    </>
                  ))}
                </div>
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <p style={{ color: "#8E8E8E", width: "160px" }}>Price</p>:
              <p style={{width: "250px", paddingLeft: "50px"}}>{transactiondata[0]?.amount}€</p>
            </div>
          </div>
        </div>

        {currentRole &&
          currentRole.map((role,index) => {
            if (role?.reviewerId?.currentRole == "Customer" && index == 1 && data[0]?.user?.id != null) {
              return (
                <div
                style={{
                  borderTop: "2px solid #E6EAF0",
                  padding: "40px",
                  borderBottom: "2px solid #E6EAF0",
                }}
              >
                <h3 className="font-23 font-weight-700" style={{ color: " #1F4173" }}>
                  {/* {role?.reviewerId?.currentRole} */}
                  Customer
                </h3>
                <div style={{ padding: "20px 5px" }}>
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <p style={{ color: "#8E8E8E", width: "160px" }}>Customer Name</p>:
                    <p style={{ width: "250px", paddingLeft: "50px" }}>
                      {" "}
                      {data[0].user?.firstName
                        ? data[0].user?.firstName
                        : "null"}
                    </p>
                  </div>
      
                  <div style={{ display: "flex" }}>
                    <p style={{ color: "#8E8E8E", width: "160px" }}>Customer Id</p>:
                    <p style={{ width: "250px", paddingLeft: "50px" }}>
                      {data[0]?.user?.id ? data[0]?.user?.id : "null"}
                    </p>
                  </div>
                  {deleteCustomer ? (
                    ""
                  ) : (
                    <>
                      <div style={{ display: "flex" }}>
                        <p style={{ color: "#8E8E8E", width: "160px" }}>Ratings</p>:
                        <p style={{ width: "250px", paddingLeft: "50px" }}>
                          {hideCustomer ? (
                            "Hide"
                          ) : (
                            <Rating
                          className={classes.customRatingIcon}
                          name="simple-controlled"
                          icon={
                            <img src={filledRating} fontSize="inherit" />
                          }
                          emptyIcon={
                            <img
                              src={unfilledRating}
                              fontSize="inherit"
                            />
                          }
                          value={role?.rating ? role?.rating : "null"}
                          readOnly
                        />
                          )}
                        </p>
                      </div>
                      <div style={{ display: "flex" }}>
                        <p style={{ color: "#8E8E8E", width: "160px" }}>Review</p>:
                        <p style={{ width: "250px", paddingLeft: "50px" }}>
                          {hideCustomer
                            ? "Hide"
                            : true
                            ? true
                            : "null"}
                        </p>
                      </div>
                    </>
                  )}
      
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      className="bg-transparent p-0"
                      variant="text"
                      onClick={handleVisibiltyCustomer}
                      style={{ color: "rgb(31, 65, 115)", minWidth: "48px" }}
                    >
                      {hideCustomer ? (
                        <VisibilityIcon />
                      ) : (
                        <img src={VisibilityOffIcon} />
                      )}
                    </Button>
                    <Button
                      className="bg-transparent p-0"
                      onClick={() => handleDelete(data[0].user.id)}
                      variant="text"
                      style={{ color: "rgb(31, 65, 115)", minWidth: "48px" }}
                    >
                      <img src={DeleteIcon} />
                    </Button>
                  </div>
                </div>
              </div>
              );
            } else if (role?.reviewerId?.currentRole == "LocalKing") {
              return (
                <div style={{ padding: "40px", borderBottom: "2px solid #E6EAF0" }}>
                <h3 style={{ color: " #1F4173" }} className="font-23 font-weight-700">
                  {/* {role?.reviewerId?.currentRole} */}
                  {role?.reviewerId?.currentRole == "LocalKing" ? "LocalKing" : "LocalKing"}
                </h3>
                <div style={{ padding: "20px 5px" }}></div>
      
                <div style={{ display: "flex" }}>
                  <p style={{ color: "#8E8E8E", width: "160px" }}>LocalKing Name</p> :
                  <p style={{ width: "250px", paddingLeft: "50px" }}>
                    {data[0].localKing?.firstName
                      ? data[0].localKing?.firstName
                      : "null"}
                  </p>
                </div>
                <div style={{ display: "flex" }}>
                  <p style={{ color: "#8E8E8E", width: "160px" }}>LocalKing Id</p> :
                  <p style={{ width: "250px", paddingLeft: "50px" }}>
                    {data[0].localKing.id ? data[0].localKing.id : "null"}
                  </p>
                </div>
      
                <div style={{ display: "flex" }}>
                  <p style={{ color: "#8E8E8E", width: "160px" }}>Ratings</p> :
                  <p style={{ width: "250px", paddingLeft: "50px" }}>
                    {hideLocalKing ? (
                      "Hide"
                    ) : (
                      <Rating
                    className={classes.customRatingIcon}
                    name="simple-controlled"
                    icon={<img src={filledRating} fontSize="inherit" />}
                    emptyIcon={
                      <img src={unfilledRating} fontSize="inherit" />
                    }
                    value={role?.rating ? role?.rating : "null"}
                    readOnly
                  />
                    )}
                  </p>
                </div>
                <div style={{ display: "flex" }}>
                  <p style={{ color: "#8E8E8E", width: "160px" }}>Review</p> :
                  <p style={{ width: "250px", paddingLeft: "50px" }}>
                    {hideLocalKing ? "Hide" : true ? true : "null"}
                  </p>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    className="p-0"
                    variant="text"
                    onClick={handleVisibiltyLocakhing}
                    style={{ color: "rgb(31, 65, 115)", minWidth: "48px" }}
                  >
                    {hideLocalKing ? (
                      <VisibilityIcon />
                    ) : (
                      <img src={VisibilityOffIcon} />
                    )}
                  </Button>
                  <Button
                    className="bg-transparent p-0"
                    onClick={() => handleDelete(data[0].localKing.id)}
                    variant="text"
                    style={{ color: "rgb(31, 65, 115)", minWidth: "48px" }}
                  >
                    <img src={DeleteIcon} />
                  </Button>
                </div>
              </div>
              );
            } else {
              return null;
            }
          })}

        <div style={{ padding: "40px" }}>
          <h3 className="font-23 font-weight-700" style={{ color: " #1F4173" }}>
            Chat History
          </h3>
          <div style={{ display: "flex" }}>
            <div
              style={{
                // width: "200px",
                display: "flex",
                padding: "20px 5px",
              }}
            >
              <p style={{ color: "#8E8E8E", width: "160px", marginBottom: 0 }}>
                Chat History
              </p>{" "}
              :{" "}
              <p className="mb-0" style={{ marginLeft: "48px" }}>
                9300
              </p>
            </div>
            <Button
              className="bg-transparent"
              variant="text"
              style={{ color: "rgb(31, 65, 115)" }}
            >
              <img src={FileDownloadIcon} />
            </Button>
          </div>
        </div>
      </div>
    </Layout>
  );
}
