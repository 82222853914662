export function formatDateAndTime(dateAndTime, format = "dateTime") {

    var expireDateAndTime = new Date(dateAndTime)

    var year = expireDateAndTime.getFullYear(),
        month = expireDateAndTime.getMonth() + 1,
        day = expireDateAndTime.getDate(),
        hour = expireDateAndTime.getHours(),
        minute = expireDateAndTime.getMinutes(),
        second = expireDateAndTime.getSeconds(),

        hourFormatted_AM_and_PM = hour % 12 || 12,

        // Time Format with ZERO 
        minuteFormatted = minute < 10 ? "0" + minute : minute,
        secondFormatted = second < 10 ? "0" + second : second,
        hourFormatted = hourFormatted_AM_and_PM < 10 ? "0" + hourFormatted_AM_and_PM : hourFormatted_AM_and_PM,

        // Date Format with ZERO 
        monthFormatted = month < 10 ? "0" + month : month,
        dayFormatted = day < 10 ? "0" + day : day,

        morning = hour < 12 ? " AM" : " PM";

    if (format === "dateTime") {
        // return year + "/" + monthFormatted + "/" + dayFormatted + " " + hourFormatted + ":" + minuteFormatted + " " + morning;
        return dayFormatted + "/" + monthFormatted + "/" + year + " " + hourFormatted + ":" + minuteFormatted + " " + morning;
        
    } else if (format === "date") {
        return dayFormatted + "/" + monthFormatted + "/" +  year;
    } else if (format === "time") {
        return hourFormatted + ":" + minuteFormatted + " " + morning;
    }

}

export function formatDateAndTimeForCoupon(dateAndTime) {
    var expireDateAndTime = new Date(dateAndTime)
    var year = expireDateAndTime.getFullYear(),
        month = expireDateAndTime.getMonth() + 1,
        day = expireDateAndTime.getDate(),
        hour = expireDateAndTime.getHours(),
        minute = expireDateAndTime.getMinutes(),
        second = expireDateAndTime.getSeconds(),
        hourFormatted_AM_and_PM = hour % 12 || 12,
        // Time Format with ZERO
        minuteFormatted = minute < 10 ? "0" + minute : minute,
        secondFormatted = second < 10 ? "0" + second : second,
        hourFormatted = hourFormatted_AM_and_PM < 10 ? "0" + hourFormatted_AM_and_PM : hourFormatted_AM_and_PM,
        // Date Format with ZERO
        monthFormatted = month < 10 ? "0" + month : month,
        dayFormatted = day < 10 ? "0" + day : day,
        morning = hour < 12 ? "AM" : "PM";
    return year + "-" + monthFormatted + "-" + dayFormatted + " " + hourFormatted + ":" + minuteFormatted + ":" + secondFormatted;
}


// export function formatDateAndTimeForCoupon(dateAndTime) {

//     var expireDateAndTime = new Date(dateAndTime)

//     var year = expireDateAndTime.getFullYear(),
//         month = expireDateAndTime.getMonth() + 1,
//         day = expireDateAndTime.getDate(),
//         hour = expireDateAndTime.getHours(),
//         minute = expireDateAndTime.getMinutes(),
//         second = expireDateAndTime.getSeconds(),

//         hourFormatted_AM_and_PM = hour % 12 || 12,

//         // Time Format with ZERO 
//         minuteFormatted = minute < 10 ? "0" + minute : minute,
//         secondFormatted = second < 10 ? "0" + second : second,
//         hourFormatted = hourFormatted_AM_and_PM < 10 ? "0" + hourFormatted_AM_and_PM : hourFormatted_AM_and_PM,

//         // Date Format with ZERO 
//         monthFormatted = month < 10 ? "0" + month : month,
//         dayFormatted = day < 10 ? "0" + day : day,

//         morning = hour < 12 ? "AM" : "PM";

//     // return year + "-" + monthFormatted + "-" + dayFormatted + " " + hourFormatted + ":" + minuteFormatted + ":" + secondFormatted; 
//     return dayFormatted + "-" + monthFormatted + "-" + year + " " + hourFormatted + ":" + minuteFormatted + ":" + secondFormatted; 
// }

export function tConvert(time) {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
        time = time.slice(1); // Remove full string match value
        time[5] = +time[0] < 12 ? ' am' : ' pm'; // Set AM/PM
        time[0] = (+time[0] % 12) < 10 ? "0" + (+time[0] % 12) : (+time[0] % 12)  || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
}


